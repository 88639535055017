









import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
})
export default class ErrorTemplateComponent extends Vue {
  @Prop() public title?: string;
  @Prop() public text?: string;
  @Prop() public imagePath?: string;
}

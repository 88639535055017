





import { Component, Vue } from "vue-property-decorator";
import ErrorTemplateComponent from '@/components/templates/Error.vue'

@Component({
  components: { ErrorTemplateComponent }
})
export default class NetworkErrorPage extends Vue {
  mounted() {
    
  }
}
